import { LoginCallback } from '@okta/okta-react';
import { PageLoader } from '@optools/legacy-ui/components/loading/PageLoader';
import { useAccessCheck } from '@optools/legacy-ui/hooks/useAccessCheck';
import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes as ReactRouterRoutes } from 'react-router-dom';

import Home from './Home';
import { MUIProviders } from './mui-providers';
import SecureOutlet from './SecureOutlet';

const AuditRoutes = lazy(() => import('@optools/tools/audit/audit-routes'));
const BTCToolsRoutes = lazy(() => import('@optools/tools/btc-tools/BTCToolsRoutes'));
const ESNIRoutes = lazy(() => import('@optools/tools/esni/esni-routes'));
const OverridesRoutes = lazy(() => import('@optools/tools/overrides/overrides-routes'));
const IPOverrideRoutes = lazy(() => import('@optools/tools/ip-override/ip-override-routes'));
const LoggerRoutes = lazy(() => import('@optools/tools/logger/LoggerRoutes'));
const MilestonesRoutes = lazy(() => import('@optools/tools/milestones/MilestonesRoutes'));
const MediaValidationRoutes = lazy(
  () => import('@optools/tools/media-validation/media-validation-routes'),
);
const PartnerRoutes = lazy(() => import('@optools/tools/partner/PartnerRoutes'));
const PageNotFound = lazy(() => import('./page-not-found'));
const Red5Poc = lazy(() => import('../../Red5Poc/Red5Poc'));
const EmbeddedSandbox = lazy(() => import('@optools/services/providers/sandbox'));

export default function Routes() {
  const {
    showAudit,
    showBtcTools,
    showEsni,
    showIpOverride,
    showLogger,
    showMediaValidation,
    showMilestones,
    showOverrides,
    showPartnerStreamDelivery,
    showRed5,
  } = useAccessCheck();

  return (
    <Suspense fallback={<PageLoader withDelay />}>
      <ReactRouterRoutes>
        {/* TODO: Remove MUI Providers wrapper route when Home has been refactored */}
        <Route element={<MUIProviders />}>
          <Route element={<Home />} path="/" />
        </Route>

        <Route element={<PageNotFound />} path="*" />
        <Route element={<LoginCallback />} path="/implicit/callback" />
        <Route element={<Navigate to="/" />} path="/login" />
        <Route element={<SecureOutlet />} path="/secure/*">
          {showBtcTools && <Route element={<BTCToolsRoutes />} path="btc-tools/*" />}
          {showOverrides && <Route element={<OverridesRoutes />} path="overrides/*" />}
          {showIpOverride && <Route element={<IPOverrideRoutes />} path="ip-override/*" />}
          {showMediaValidation && (
            <Route element={<MediaValidationRoutes />} path="media-validation/*" />
          )}
          {showMilestones && <Route element={<MilestonesRoutes />} path="milestones/*" />}
          {showAudit && <Route element={<AuditRoutes />} path="audit/*" />}
          {showEsni && <Route element={<ESNIRoutes />} path="esni/*" />}

          {/* TODO: As tools are migrated, move them out of the MUIProviders route to the parent "secure" route */}
          <Route element={<MUIProviders />}>
            {showLogger && <Route element={<LoggerRoutes />} path="logger/*" />}
            {showPartnerStreamDelivery && <Route element={<PartnerRoutes />} path="partner/*" />}
            {showRed5 && <Route element={<Red5Poc />} path="red5/*" />}
          </Route>
        </Route>

        {process.env.NODE_ENV === 'development' && APOLLO_SANDBOX === 'enabled' && (
          <Route element={<EmbeddedSandbox />} path="sandbox" />
        )}
      </ReactRouterRoutes>
    </Suspense>
  );
}
